import { Link } from "gatsby"
import React, { useState, useEffect, useContext } from "react"
import Layout from "../components/Layout"
import "../styles/doc.scss"
import { Helmet } from "react-helmet"

const PrivacyPolicy = () => {

  const [isMobile, setIsMobile] = useState(false)
  const checkScreenSize = () => {
    if (window.innerWidth <= 600) {
      setIsMobile(true)
    } else {
      setIsMobile(false)
    }
  }

  useEffect(() => {
    checkScreenSize()
    window.addEventListener("resize", checkScreenSize,   {passive:true})
  }, [])
  return (
    <>
    <Helmet htmlAttributes={{ lang: "en" }} title={'Privacy Policy - Cubo'}>
      <meta name="description" content={'We understand and value the privacy of our valued customers and website users.'} />
      <link rel="canonical" href='https://cubo.to/privacypolicy/'/>
    </Helmet>
    <Layout>
      <div
        className="privacypolicy-section"
        style={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "1024px",
          padding: isMobile ? "0 20px" : "",
          margin: isMobile ? "" : "20px auto",
        }}
      >
        <div style={{ maxWidth: "100%", color: "#000" }}>
          <h1
            style={{
              fontSize: "24px",
              margin: "0 0 20px",
              fontWeight: "500",
              textAlign: "center",
              fontFamily:'Poppins-Bold'
            }}
          >
            Privacy Policy
          </h1>
          <div style={{ fontSize: "16px", marginBottom: "20px" }}>
            Last Update: [November 9], 2022
          </div>
          <h4 className="h4">Introduction</h4>
          <div className="content">
            Cubo World Inc.(hereinafter referred to as “Cubo”, “we” or “us”)
            takes your privacy very seriously, and we are committed to
            protecting and respecting your privacy. That value powers all of the
            decisions we make, including how we collect, use, share, store and
            respect your Personal Data. Please read this Privacy Policy
            carefully so that you can make appropriate decisions on an informed
            and freely given basis.
          </div>
          <div className="bold content px14">
            PLEASE NOTE, IF YOU CONTINUE USING OR ACCESSING OUR SERVICES, YOU
            WILL BE DEEMED THAT YOU HAVE FULLY UNDERSTOOD AND ACCEPTED, AND IS
            WILLING TO COMPLY WITH ALL THE FOLLOWING CLAUSES, AND THIS TERMS
            SHALL BECOME EFFECTIVE BETWEEN YOU AND US. IF YOU DISAGREE WITH THIS
            TERMS IN WHOLE OR PART, YOU SHALL IMMEDIATELY STOP USING OUR
            SERVICES.
          </div>
          <div className="bold content px14">
            Our services are currently available to users in North America and
            the EEA and the United Kingdom. If you are not a user in these
            regions, you may not receive adequate protection from this Privacy
            Policy, so please be aware of the privacy laws and regulations in
            your region. Cubo World Inc. will not discriminate against any user
            in regions where we are not yet able to provide services
          </div>

          <h4 className="h4">Updates To This Privacy Policy</h4>
          <div className="content">
            We may amend this Privacy Policy from time to time, and we will
            notify you about such changes by posting the amended version on our
            websites and application. Please check back periodically to ensure
            that you are aware of any changes, and your continued use of our
            application will be deemed your acceptance of these changes.
          </div>

          <h4 className="h4">Children’s Personal Data</h4>
          <div className="content">
            Unless otherwise specified, our application are designed for a
            general audience and are not directed to children. In connection
            with the Services, we do not knowingly solicit or collect Personal
            Data from children under the age of 13 without parental consent. If
            parents believe that we have unintentionally collected their
            children’s’ Personal Data, please contact us to delay the
            information via appropriate means as set forth in “Contact Details”
            Section of this Privacy Policy.
          </div>

          <h4 className="h4">Scope</h4>

          <div className="content">
            This Privacy Policy applies to users of【CUBO】application (“our
            application”) , websites and related services (the “Service”)
            developed and operated by us. It is important that you read this
            Privacy Policy together with any other privacy policy or fair
            processing policy we may provide on specific occasions, so that you
            are fully aware of when, why and how we collect and process your
            Personal Data. This Privacy Policy supplements other notices and
            privacy policies and is not intended to override them.
          </div>

          <div className="content">
            Unless otherwise prescribed in the Privacy Policy, the terms in the
            Privacy Policy shall have the same implications with the terms in
            the <Link to={"/termsofservice/"}>Terms of Service</Link>.
          </div>

          <div className="content" style={{ fontSize: "14px" }}>
            <span
              style={{
                fontSize: "14px",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              PLEASE NOTE THAT
            </span>
            , this Privacy Policy does
            <span
              style={{
                fontSize: "14px",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
            >
              NOT
            </span>
            apply to the following cases:
            <ul>
              <li style={{ marginTop: "10px" }}>
                Any information or Personal Data collected by any third party
                services, products, applications, websites and/or platforms,
                regardless whether they are accessible through our application;
                or
              </li>

              <li style={{ marginTop: "10px" }}>
                Any information or Personal Data collected by other companies or
                institutions that provide advertisments through our services.
              </li>
            </ul>
          </div>

          <div className="content">
            In such circumstances, you should refer directly to the Privacy
            Policies for the relevant third party.
          </div>
          <div className="content">
            Please understand that we cannot know your approximate location
            until you log in for the first time, at which point your device
            information such as IP or MAC will be sent to us. Therefore, if you
            are outside the scope of our services, but sigh up and use our
            services, we consider that you agree to the contents of this Privacy
            Policy.
          </div>

          <h4 className="h4">Personal Data We Collect</h4>

          <div className="content">
            The term "Personal Data" under this Privacy Policy means any
            information relating to an identified or identifiable natural person
            including information that specifically identifies an individual
            (such as a name, address, telephone number, mobile number, e-mail
            address, credit card number, ACH bank information, identification
            number, location data, or an online identifier) and information
            about that individual or his or her activities that is directly
            linked to the individual. PLEASE NOTE that Personal Data does not
            include aggregate data, which is data we collect about the use of
            our Services or about a group or category of services or users, from
            which an individual’s identity cannot be discerned and all other
            Personal Data has been removed.
          </div>

          <div
            className="content"
            style={{ marginBottom: "10px", fontSize: "14px" }}
          >
            We may collect your Personal Data as follows:
          </div>

          <div
            className="content"
            style={{ marginBottom: "10px", fontSize: "14px" }}
          >
            a. Technical Information We Collect About You. The technical
            information about the device(s) and/or browsers you use may be
            automatically collected. This includes your IP address, browsing
            type, mobile model, operating systems, network type, IMEI, MAC
            address and other browse settings/preference, etc. (collectively
            “Log Data”). The Log Data is stored by us and used to identify each
            unique visit to our application, establish and maintain the
            technical connection to our application, and to provide you with
            better browsing experience.
          </div>

          <div className="content" style={{ margin: "20px 0" }}>
            b. Basic Registration Information.
          </div>

          <ul>
            <li className="content" style={{ marginBottom: "10px" }}>
              To register as a Cubo personal user, you need to provide your
              email address, company name and preferred name to create a Cubo
              account, and thereafter you may optionally upload your photo.
            </li>

            <li className="content" style={{ marginBottom: "10px" }}>
              To register as a Cubo organization user, you need to provide your
              organization name, team size, currency, logo (optional), preferred
              settings, team member’s email and role.
            </li>

            <li className="content" style={{ marginBottom: "10px" }}>
              To access Cubo as a visitor, you need to provide your email
              address for access. In addition, we may send you advertisements
              based on your consent ( if you are a California user, you can
              refuse these advertisements by selecting opt-out in the settings).
            </li>
          </ul>

          <div className="content">
            c. <span className="bold">Task Information</span>. When you use our
            Services, we collect certain information about your inputs into our
            Services which may contain Personal Data, such as comments,
            attachments, and daily stand-ups entered by users of our Services.
          </div>

          <div className="content">
            d. <span className="bold">Contact Details</span>. We may collect
            your email address or any other contact details provided by you in
            case you contact us for certain services, and we will only store
            such information for the time necessary to address your request.
          </div>

          <div className="content">
            e. <span className="bold">Geo-location information</span>. If you
            are accessing our Services from a mobile device or through a mobile
            application, you may be asked to share your precise (GPS level)
            geo-location information with us so we can customize your experience
            on our Services. Such geolocation information may include physical
            locations visited (latitude & longitude). If you agree to such
            collection, in most cases, you will be able to turn off such data
            collection at any time by accessing the privacy settings of your
            mobile device and/or through the setting in the applicable mobile
            application.
          </div>

          <div className="content bold">
            f. Keyboard Active Status. We may also collect information about
            whether your keyboard is being typed on if the organization you
            joined has chosen to use our monitor feature. PLEASE NOTE that we
            will only record whether your keyboard is active or inactive,
            instead of what you are typing.
          </div>

          <div className="content">
            g. <span className="bold">Public information</span>. We may also
            collect information about you from publicly available sources.
            Information you make publicly available in any public or open forum,
            such as on a social network, may be considered public information
            for the purposes of this Privacy Policy, and may be accessed and
            collected by us. Please be aware that any content or information you
            share with or provide to third parties using or related to your use
            of our Services is neither private nor confidential. We are not
            responsible for any content or information you post or share with
            third parties. If you do not wish for certain information to be
            public, please do not share it publicly.
          </div>

          <div className="content bold">
            h. We may also collect other information about you, your devices and
            your use of our Services, in ways we describe at the time we collect
            the information or otherwise with your consent.
          </div>

          <div className="content">
            <span className="bold">We DO NOT</span> collect any special
            categories of Personal Data about you (including details about your
            race or ethnicity, religious or philosophical beliefs, sex life,
            sexual orientation, political opinions, trade union membership,
            information about your health and genetic and biometric data).
          </div>

          <h4 className="h4">How We Use Your Personal Data</h4>
          <div className="content">
            We will use the Personal Data we collect about you in accordance
            with the laws of applicable jurisdictions in the following ways:
          </div>

          <div className="content">
            a. To enter into and to perform our contract with you, or to perform
            any steps you require from us before entering into a contract with
            you;
          </div>

          <div className="content">
            b. To communicate with you, including but not limited to respond to
            your request for any help with our Services or for exercising your
            rights with respect to your Personal Data;
          </div>

          <div className="content">
            c. To check your online activities for the advertising purpose (if
            you permit);
          </div>

          <div className="content">
            d. To pursue our legitimate interests（or those of a third party）as
            we deem fit and necessary, where your interests and fundamental
            rights do not override those legitimate interests of ours (or any
            third party’s), such as:
            <ul>
              <li style={{ marginTop: "10px" }}>
                to understand, improve, optimize and develop our application;
              </li>
              <li style={{ marginTop: "10px" }}>
                to conduct research and monitor the quality of our application;
              </li>
              <li style={{ marginTop: "10px" }}>
                to respond to complaints, hearings, arbitration, law suits or
                other governmental or regulatory proceedings;
              </li>
            </ul>
          </div>

          <div className="content">
            e. To comply with our legal obligations under relevant laws,
            regulations, and orders (including subpoenas), injunctions, or any
            other legal documents issued by the court or competent governmental
            or regulatory authorities.
          </div>

          <div className="content">
            <span className="bold">PLEASE NOTE THAT</span>, we do not sell or
            share your Personal Data with any third parties for their direct
            marketing, but those third parties who provide us with SDKs may
            collect your Personal Data directly, please refer to “How We Share
            Your Personal Data” Section for detailed information.
          </div>

          <h4 className="h4">Communications</h4>
          <div className="content">
            We engage in Services and promotional communications, through email,
            phone, SMS and notifications.
          </div>

          <div className="content">
            Services Communications: We may contact you with important
            information regarding our Services. For example, we may send you
            notifications (through any of the means available to us) of changes
            or updates to our Services, billing issues, log-in attempts or
            password reset notices, etc. Our Customers, and other Users on the
            same Account, may also send you notifications, messages and other
            updates regarding their or your use of the Services. You can control
            your communications and notifications settings from your User
            Profile settings, or otherwise in accordance with the instructions
            that may be included in the communications sent to you. However,
            please note that you will not be able to opt-out of receiving
            certain Services communications which are integral to your use (like
            password resets or billing notices).
          </div>

          <div className="content">
            Promotional Communications: We may also notify you about new
            features, additional offerings, events and special opportunities or
            any other information we think you will find valuable, as our
            Customer, User or Prospect. We may provide such notices through any
            of the contact means available to us (e.g. phone, mobile or email),
            through the Services, or through our marketing campaigns on any
            other sites or platforms. If you do not wish to receive such
            promotional communications, you may notify cubo.to at any time
            by sending an email to：contact@cubo.to, changing your
            communications preferences in your User Profile settings, or by
            following the “unsubscribe”, “stop”, “opt-out” or “change email
            preferences” instructions contained in the promotional
            communications you receive.
          </div>

          <h4 className="h4">How We Share Your Personal Data</h4>

          <div className="content">
            We will not share your Personal Data with companies, organizations
            or individuals other than our service providers, except in the
            following circumstances:
          </div>

          <div className="content">
            a. Sharing with explicit consent. We will share your Personal Data
            with other parties with your explicit consent;
          </div>
          <div className="content">
            b. Sharing as required by laws. We may share your Personal Data as
            required by laws, regulations, litigation, dispute resolution, or by
            administrative or judicial authority in accordance with laws;
          </div>
          <div className="content">
            c. Sharing with our vendors. We may share your information with our
            vendors that perform support and other services in connection with
            our application as follows:
            <ul>
              <li style={{ marginTop: "10px" }}>
                with advertising service providers and advertising partners that
                enable us to conduct and display advertising on our application,
                website, and third-party sites, applications, or social media
                platforms;
              </li>
              <li style={{ marginTop: "10px" }}>
                with third parties for collaborative offerings, legal and safety
                purposes, in connection with the sale or transfer of a business
                or asset, for use in aggregate or anonymous form, and for other
                purposes with your permission;
              </li>
              <li style={{ marginTop: "10px" }}>
                with the following SDK providers:
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">AWS. </span> AWS is a cloud platform
                  that provides compute, storage, and databases services. For
                  more information about how AWS collect and use your Personal
                  Data, please refer to its privacy policies by the following
                  link:
                  <a
                    href="https://aws.amazon.com/privacy/"
                    className="link"
                    target="__blank"
                  >
                    https://aws.amazon.com/privacy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Twilio. </span>
                  Twilio is a cloud communications platform that provides
                  software developers with building blocks to add communications
                  to web and mobile applications or manage email applications
                  (such as through our SendGrid products and services).For more
                  information about how Twilio collect and use your Personal
                  Data, please refer to its privacy policies by the following
                  link:
                  <a
                    href="https://www.twilio.com/legal/privacy"
                    className="link"
                    target="__blank"
                  >
                    https://www.twilio.com/legal/privacy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Xuifei. </span>
                  Xunfei provides Voice-to-Text Services for Applications. For
                  more information about how Xunfei collect and use your
                  Personal Data, please refer to its privacy policies by the
                  following link:
                  <a
                    href="http://x.xunfei.cn/mall/privacy.html"
                    className="link"
                    target="__blank"
                  >
                    http://x.xunfei.cn/mall/privacy.html
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Qiniu. </span>
                  Qiniu is a multimedia data processing platform providing
                  picture, video and audio storage services for applications.
                  For more information about how Qiniu collect and use your
                  Personal Data, please refer to its privacy policies by the
                  following link:
                  <a
                    href="https://www.qiniu.com/agreements/privacy-right"
                    className="link"
                    target="__blank"
                  >
                    https://www.qiniu.com/agreements/privacy-right
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Agora. </span>
                  Agora provides full IM capabilities for applications, such as
                  single chat, group chat, and chat rooms. For more information
                  about how Agora collect and use your Personal Data, please
                  refer to its privacy policies by the following link:
                  <a
                    href="https://www.agora.io/cn/privacy-policy"
                    className="link"
                    target="__blank"
                  >
                    https://www.agora.io/cn/privacy-policy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Auth0 </span>
                  is an easy to implement, adaptable authentication and
                  authorization platform.
                  <span className="bold">
                    For more information about how Auth0 collect and use your
                    Personal Data, please refer to its privacy policies by the
                    following link:
                  </span>
                  <a
                    href="https://auth0.com/security"
                    className="link"
                    target="__blank"
                  >
                    https://auth0.com/security
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Clearout. </span>
                  Clearout Is a platform for email authentication and
                  authorization. For more information about how Clearout collect
                  and use your Personal Data, please refer to its privacy
                  policies by the following link:
                  <a
                    href="https://clearout.io/privacy-policy/"
                    className="link"
                    target="__blank"
                  >
                    https://clearout.io/privacy-policy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Nylas. </span>
                  Nylas is a scheduling and calendar editing service that
                  provides text and date storage for applications.For more
                  information about how Nylas collect and use your Personal
                  Data, please refer to its privacy policies by the following
                  link:
                  <a
                    href="https://www.nylas.com/privacy-policy/"
                    className="link"
                    target="__blank"
                  >
                    https://www.nylas.com/privacy-policy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Stripe. </span>
                  Stripe Is an internet payment kiosk platform that provides
                  application functionality with the payment module.For more
                  information about how Stripe collect and use your Personal
                  Data, please refer to its privacy policies by the following
                  link:
                  <a
                    href="https://stripe.com/privacy/"
                    className="link"
                    target="__blank"
                  >
                    https://stripe.com/privacy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Mailgun. </span>
                  Mailgun Is a platform for processing complex and large volumes
                  of email, providing email processing services for software
                  developers and technicians. For more information about
                  how mailgun collect and use your Personal Data, please refer
                  to its privacy policies by the following link:
                  <a
                    href="https://www.mailgun.com/legal/privacy-policy/"
                    className="link"
                    target="__blank"
                  >
                    https://www.mailgun.com/legal/privacy-policy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Mailreach. </span>
                  Mailreach Is a mailbox warm-up platform, for applications to
                  provide fast and efficient interaction features such as mail
                  services.For more information about how Mailreach collect and
                  use your Personal Data, please refer to its privacy policies
                  by the following link:
                  <a
                    href="https://www.mailreach.co/privacy-policy/"
                    className="link"
                    target="__blank"
                  >
                    https://www.mailreach.co/privacy-policy
                  </a>
                </div>
                <div className="content" style={{ marginTop: "10px" }}>
                  <span className="bold">Mailchimp. </span>
                  Mailchimp Is an email marketing platform that provides
                  applications with personalization, automation, and premium
                  content editing features.For more information about
                  how Mailchimp collect and use your Personal Data, please refer
                  to its privacy policies by the following link:
                  <a
                    href="https://www.intuit.com/privacy/statement/"
                    className="link"
                    target="__blank"
                  >
                    https://www.intuit.com/privacy/statement
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div className="content">
            Because Cubo World Inc. is based in the United States, information
            collected in other countries/regions is usually transmitted to the
            United States for processing. Cubo World Inc. participates in the
            EU-US Privacy Shield frameworks II and Swiss-US Privacy Shield
            frameworks ( as to Frameworks ) regarding the collection, use, and
            retention of personal information from European Union member
            countries, the United Kingdom and Switzerland.
          </div>

          <div className="content">
            In the event of any conflict between this Privacy Policy and the
            Frameworks, the terms of the Frameworks shall govern.
          </div>

          <h4 className="h4">Cookies</h4>

          <div className="content">
            We use cookies and other similar technologies(“Cookies”) to help us
            remember your information so you will not have to re-enter it,
            tailor our application around your preferences, and otherwise
            enhance your experience using our application. Cookies are small
            files which, when placed on your device, enable us to provide
            certain features and functionalities.
          </div>

          <div className="content">
            You have the option to permit installation of such Cookies
            or subsequently disable them. You may accept all cookies, or
            instruct the device (in the case of the game) or web browser (in the
            case of the website) to provide notice at the time of installation
            of cookies, or refuse to accept all cookies by adjusting the
            relevant cookie retention function in your device.
          </div>

          <h4 className="h4">How We Protect Your Personal Data</h4>

          <div className="content">
            We endeavors to take appropriate measures to safeguard and secure
            your Personal Data collected by us. For example, we have established
            internal procedures to restrict employees to access such
            information, only employees who need to use the information for
            performing their duties will be allowed to access such information,
            and they are required to comply with confidential policies. Although
            we endeavors to protect your Personal Data, PLEASE NOTE that
            information sent over the internet is not necessarily secure and may
            be intercepted or accessed by third parties. Therefore, we cannot
            guarantee the security of such information, and we are not liable
            for any of such interception. You SHALL take care with regard to how
            you handle and disclose your Personal Data or any username or
            password that you are required to use to access services on our
            applications.
          </div>

          <h4 className="h4">International Transfer</h4>

          <div className="content">
            Except as otherwise stated in this Privacy Policy, any Personal Data
            we collected will be stored on the server located in the United
            States. In case we need to transfer any of your Personal Data out of
            the United States, we will take steps to ensure that your Personal
            Data continues to enjoy appropriate protections.
          </div>

          <div className="content">
            If any change happens to our international transfer policy and we
            need to transmit your Personal Data outside of the United States, we
            will update this Privacy Policy and notify you of such change.
          </div>

          <h4 className="h4">Your Rights and Controls</h4>

          <div className="content">
            You have rights under applicable data protection law in relation to
            our use of your Personal Data, including:
            <div className="sub_content">
              (a)
              <span className="bold">
                Your choices about information provided by you.
              </span>
              If you do not want to provide us your Personal Data, then you can
              choose not to provide it. However, if you fail to provide us with
              Personal Data, we may unable to provide you with certain product
              or service；
            </div>
            <div className="sub_content">
              (b) <span className="bold">Objection.</span> You have the right to
              object to certain uses of your Personal Data, including direct
              marketing (by click unsubscribe link in the email), processing
              based on legitimate interests, and processing for purposes of
              scientific or historical research and statistics, on grounds
              relating to your particular situation;
            </div>
            <div className="sub_content">
              (c) <span className="bold">Erasure.</span> You have the right to
              request the deletion of your Personal Data, or restrict its use,
              in certain circumstances (for example you can request us to erase
              your contact detail where it is no longer necessary for the
              purpose for which it was collected unless certain exceptions
              apply);
            </div>
            <div className="sub_content">
              (d)
              <span className="bold">Access Your Data and Portability:</span>
              You can ask us to confirm we process your Personal Data and for a
              copy of your Personal Data in a machine readable form；and
            </div>
            <div className="sub_content">
              (e) <span className="bod">Withdrawing Consent.</span> If we are
              processing your Personal Data based on your consent you can
              withdraw your consent at any time by email us and specifying which
              consent you are withdrawing. PLEASE NOTE that the withdrawal of
              your consent does not affect the lawfulness of any processing
              activities based on such consent before its withdrawal.
            </div>
            <div className="sub_content">
              (f) <span className="bold">Opting of email marketing:</span> You
              can unsubscribe from our marketing email list at any time by
              clicking on the unsubscribe link in the emails that we send or by
              contacting us using the details provided below. You will then be
              removed from the marketing email list——however, we may still
              communicate with you, for example to send you service-related
              emails that are necessary for the administration and use of your
              account, to respond to service requests, or for other
              non-marketing purposes. To otherwise opt-out, you may:
              <div>Contact us using the contact information provided.</div>
              <div>
                Access your account settings and update your preferences.
              </div>
            </div>
          </div>

          <div className="content bold">
            PLEASE NOTE that, if your account on our application is not
            registered by yourself, but provided by your company, then your
            company shall be the data controller instead of us, and therefore,
            you may need to exercise the rights set out above through your
            company.
          </div>

          <div className="content">
            If you have any question about these rights or you would like
            exercise any of them, please contact us via appropriate means as set
            forth in “Contact Details” Section of this Privacy Policy. Before we
            can respond to a request to exercise one or more of the rights
            listed above, you may be required to verify your identity or your
            account details. Please note that your ability to access or control
            your Personal Data will be limited in some cases, as required or
            permitted by applicable law.
          </div>
          <div className="content">
            If you are a resident in the EEA or UK or Switzerland and you
            believe we are unlawfully processing your personal information,
            please contact us at:contact@cubo.to; you also have the right
            to complain to your local dara protection supervisory authority.
          </div>

          <div className="content">
            Cubo World Inc. are subject to the investigatory and enforcement
            powers of the Federal Trade Commission (FTC). We may be required to
            disclose personal information that we handle under the Privacy
            Shield in response to lawful requests by public authorities,
            including to meet national security or law enforcement requirements.
          </div>

          <h4 className="h4">European Data Protecticon Specific Information</h4>

          <div className="content bold" style={{ marginBottom: "10px" }}>
            Data Subjects Rights
          </div>

          <div className="content" style={{ marginTop: "10px" }}>
            If you are in the EEA, Switzerland, or the UK, your rights in
            relation to your personal data processed by us as a controller
            specifically include:
            <ul>
              <li>
                Right of access and/or portability: You have the right to access
                any personal data that we hold about you and, in some
                circumstances, have that data provided to you so that you can
                provide or “port” that data to another provider;
              </li>
              <li>
                Right of erasure: In certain circumstances, you have the right
                to the erasure of personal data that we hold about you (for
                example, if it is no longer necessary for the purposes for which
                it was originally collected);
              </li>
              <li>
                Right to object to processing: In certain circumstances, you
                have the right to request that we stop processing your personal
                data and/or stop sending you marketing communications;
              </li>
              <li>
                Right to rectification: You have the right to require us to
                correct any inaccurate or incomplete personal data;
              </li>

              <li>
                Right to restrict processing: You have the right to request that
                we restrict processing of your personal data in certain
                circumstances (for example, where you believe that the personal
                data we hold about you is not accurate or lawfully held).
              </li>
            </ul>
          </div>

          <div className="content">
            To exercise your rights, please click 
            <a
              href="https://zoom-privacy.my.onetrust.com/webform/65962359-ef0d-4399-9db4-572d06de08aa/f277f9f7-bfee-4233-815e-80e290139bc2"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            . If you have any other questions about our use of your personal
            data, please send a request at the contact details specified in the 
            <Link to="/contact-us/">Contact Us</Link>  section of this Privacy
            Statement. Please note that we may request you to provide us with
            additional information in order to confirm your identity and ensure
            that you are entitled to access the relevant personal data.
          </div>

          <div style={{ marginBottom: "10px" }}>
            You also have the right to lodge a complaint to a data protection
            authority. For more information, please contact your local data
            protection authority.
          </div>

          <div className="bold" style={{ marginBottom: "10px" }}>
            Legal Basis for Processing Personal Data
          </div>

          <div>
            We only use your information in a lawful, transparent, and fair
            manner. Depending on the specific personal data concerned and the
            factual context, when Cubo processes personal data as a controller
            for individuals in regions such as the EEA, Switzerland, and the UK,
            we rely on the following legal bases as applicable in your
            jurisdiction:
            <ul>
              <li>
                <span className="bold">As necessary for our contract:</span>
                When we enter into a contract directly with you, we process your
                personal data on the basis of our contract in order to prepare
                and enter into the contract, as well as to perform and manage
                our contract (i.e., providing Cubo products and services,
                features and services to account owners, their users, and those
                they invite to join meetings and webinars hosted on their
                accounts, and manage our relationship and contract, including
                billing, compliance with contractual obligations, and related
                administration). If we do not process your personal data for
                these purposes, we may not be able to provide you with all
                products and services;
              </li>

              <li>
                <span className="bold">
                  Consistent with specific revocable consents:
                </span>
                We rely on your prior consent in order to utilize cookies to
                engage advertising and analytics partners to deliver tailored
                advertising and analysis of our website usage. You have the
                right to withdraw your consent at any time by visiting our
                cookie management tool, available Cookies Settings;
              </li>
              <li>
                <span className="bold">
                  As necessary to comply with our legal obligations:
                </span>
                We process your personal data to comply with the legal
                obligations to which we are subject for the purposes of
                compliance with EEA laws, regulations, codes of practice,
                guidelines, or rules applicable to us, and for responses to
                requests from, and other communications with, competent EEA
                public, governmental, judicial, or other regulatory authorities.
                This includes detecting, investigating, preventing, and stopping
                fraudulent, harmful, unauthorized, or illegal activity (“fraud
                and abuse detection”) and compliance with privacy laws;
              </li>
              <li>
                <span className="bold">
                  To protect your vital interests or those of others:
                </span>
              </li>
              <li>
                <span className="bold">
                  We process certain personal data in order to protect vital
                  interests for the purpose of detecting and preventing illicit
                  activities that impact vital interests and public safety,
                  including child sexual abuse material; and
                </span>
              </li>
              <li>
                <span className="bold">
                  As necessary for our (or others’) legitimate interests, unless
                  those interests are overridden by your interests or
                  fundamental rights and freedoms, which require protection of
                  personal data:
                </span>
                We process your personal data based on such legitimate interests
                to (i) enter and perform the contract with the account
              </li>
              <li>
                owner and/or reseller providing you with the products and
                services (which includes billing, compliance with contractual
                obligations, and related administration and support); (ii)
                develop, test, and improve our products and services and
                troubleshoot products and services; (iii) ensure authentication,
                integrity, security, and safety of accounts, activity, and
                products and services, including detect and prevent malicious
                conduct and violations of our terms and policies, prevent or
                investigate bad or unsafe experiences, and address security
                threats; (iv) send marketing communications, advertising, and
                promotions related to the products and services; and (v) comply
                with non-EEA laws, regulations, codes of practice, guidelines,
                or rules applicable to us and respond to requests from, and
                other communications with, competent non-EEA public,
                governmental, judicial, or other regulatory authorities, as well
                as meet our corporate and social responsibility commitments,
                protect our rights and property and the ones of our customers,
                resolve disputes, and enforce agreements.
              </li>
            </ul>
          </div>

          <h4 className="h4">California Consumer Privacy Act (“CCPA”)</h4>

          <div className="content">
            If you are a California resident, the supplemental terms in this
            section may apply to you. <span className="bold">PLEASE NOTE</span>
            that we may request proof of California residency before responding
            to requests made under this section.
          </div>

          <div className="content">
            a. <span className="bold">Right to Opt-out.</span> We do not sell
            your Personal Data or share, exchange your Personal Data with any
            third party. Therefore, we do not offer an opt-out to the sale of
            Personal Data as required by CCPA.
          </div>

          <div className="content">
            b. <span className="bold">Right to know.</span> You may submit a
            verifiable request for us to provide the following information,
            which we may be able to provide you for no more than twice in a
            12-month period with free of charge:
            <ul>
              <li>The specific pieces of Personal Data we have about you;</li>
              <li>
                The categories of Personal Data we collected, sold, or disclosed
                for a business purpose about you within the last 12 months;
              </li>
              <li>
                The categories of sources from which the Personal Data was
                collected;
              </li>
              <li>
                The purposes for which the information was collected or sold;
                and
              </li>
              <li>
                The categories of third parties to whom the information was
                sold, disclosed for a business purpose, or otherwise shared.
              </li>
            </ul>
            If possible, we will provide this information to you in a readily
            usable format that allows transmission to another entity.
          </div>
          <div className="content">
            c. <span className="bold">Right to Data Portability.</span>
            You have the right to request certain copies of your Personal Data
            you have provided to us, to move data from one company to another
            (often called the right to data portability). PLEASE NOTE that your
            recordings will be stored on your local devices, and we therefore
            are not able to provide you with any copies; and
          </div>

          <div className="content">
            d. <span className="bold">Right to Delete. </span>
            You may submit a verifiable request for us to delete the Personal
            Data we have collected about you. We may retain Personal Data
            necessary to: (i) protect our business, systems, and users from
            fraudulent activity; (ii) comply with law enforcement requests
            pursuant to lawful process; or (iii) for scientific or historical
            research.
          </div>

          <div className="content">
            e. <span className="bold">You WIll NOT</span>
            be discriminated (such as price discrimination) for exercising your
            rights under CCPA.
          </div>

          <div className="content">
            Please contact us via appropriate means as set forth in “Contact
            Details” Section of this Privacy Policy if you would like to
            exercise your rights under this section.
          </div>

          <div className="content">
            If you are a Nevada resident, the supplemental terms in this section
            may apply to you. <span className="bold">PLEASE NOTE</span> that we
            may request proof of Nevada residency before responding to requests
            made under this section.
          </div>

          <div className="content">
            According to Nevada Act, Nevada residents who have purchased goods
            or services from us shall be entitled to opt out of the sale of
            covered information for monetary consideration to a person for that
            person to license or sell such information to additional persons.
            “Covered Information” including (i) a first and last name; (ii) a
            home or other physical address which includes the name of a street
            and the name of a city or town; (iii) an email address; (iv) a
            telephone number; (v) a social security number; (vi) an identifier
            that allows a specific person to be contacted either physically or
            online; (vii) any other information concerning a person collected
            from the person through our website or online service and maintained
            by us in combination with an identifier in a form that makes the
            information personally identifiable.
          </div>

          <div className="content">
            Again, we <span className="bold">DO NOT</span> sell your Personal
            Data or share, exchange your Personal Data with any third party.
            Therefore, we <span className="bold">DO NOT</span> offer an opt-out
            to the sale of Personal Data as required by Nevada.
          </div>

          <h4 className="h4">Security of Personal Data</h4>

          <div className="content">
            The security, integrity, and confidentiality of your Personal Data
            are extremely important to us. We will use various technologies and
            administrative measures to protect your Personal Data which have
            been collected and/or stored by us from being lost, being misused,
            unauthorized accessed or leaking. In addition, all processing by our
            staff are based on their work assignments and bound by
            confidentiality obligations.
          </div>

          <div className="content">
            <span className="bold">PLEASE NOTE</span> that, despite our best
            efforts to provide security protections for your Personal Data, due
            to limit of technology as well as various possible malicious means
            in the internet industry, no security measures are perfect or
            impenetrable. We will regularly review our security procedures to
            consider appropriate new technology and methods.
          </div>

          <h4 className="h4">Where We Store Your Personal Data</h4>

          <div className="content">
            Generally, we will only retain your Personal Data (such as email
            address) on our server located in the United States. However, if you
            provide us with your Personal Data for addressing technical problems
            or feedback, we may collect and use your technical information about
            the device(s) and/or browsers (including without limitation your IP
            address, browsing type, mobile model, operating systems, network
            type and other browser settings/preference) you may use, and such
            data may be transferred to and stored on where we located to the
            extent necessary to provide support and respond as per your request.
          </div>

          <h4 className="h4">How Long We Store Your Personal Data</h4>

          <div className="content">
            Generally, we do not store your Personal Data unless we otherwise
            expressed in this Privacy Policy. In the case we need to retain your
            Personal Data for the purposes stated in this Privacy Policy, such
            retention will be limited to the necessary period which permitted by
            applicable laws and regulations. For example, when you contact us
            for feedback, complaints, bug reports, or questions regarding your
            Personal Data, we will store such data and your personal contact
            details (such as email address) to the extent for responding your
            request, and then immediately delete the same properly, EXCEPT THAT
            we have other legitimate basis (such as complying with our legal
            obligations, resolving disputes, and enforcing our agreements) for
            keeping such data.
          </div>

          <div className="content">
            <span className="bold">PLEASE NOTE THAT,</span> the recordings or
            any other contents you create or edit with our application will be
            stored in your local folder on your device, and will not be stored
            by us.
          </div>

          <h4 className="h4">Contact Us</h4>

          <div className="content">
            If you have any questions about this Privacy Policy or our privacy
            practices, or if you wish to exercise your rights in respect of your
            Personal Data, please contact us in the following ways:
          </div>

          <div className="content">
            Full name of legal entity: Cubo World Inc.
          </div>
          <div className="content">Email address: contact@cubo.to</div>
          <div className="content">
            Address: 108 West 13th Street, Wilmington, Delaware 19801, USA
          </div>
        </div>
      </div>
    </Layout>
    </>
  )
}

export default PrivacyPolicy
